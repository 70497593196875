import Navbar from 'component/Navbar/navbar';
import Footer from 'component/Footer/footer';
import { motion } from 'framer-motion';
import * as Icon from 'react-feather';
import LanguageTranslation from '../../component/LanguageTranslation';

export default function Contact() {
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 bg-[url('assets/images/company/aboutus.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="mb-6 md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold text-white">
                <LanguageTranslation id="contractUs.title" />
              </h3>
              <p className="font-semibold text-slate-300 text-lg max-w-xl mx-auto">
                <LanguageTranslation id="contractUs.subTitle" />
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      <div className="container relative">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
          viewport={{ once: true, amount: 'some' }}
        >
          <div className="lg:flex justify-center mt-24 md:mb-24">
            <div className="lg:w-11/12 bg-white dark:bg-slate-900 rounded-md shadow-lg dark:shadow-gray-800 overflow-hidden">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="w-full leading-[0] border-0">
                    <iframe
                      title="google"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50656.28067961723!2d126.80188004863282!3d37.4839124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e02c5b2549f%3A0x19e485122345ab3f!2z67K97IKw65SU7KeA7YS467C466asMuywqA!5e0!3m2!1sko!2skr!4v1701073172490!5m2!1sko!2skr"
                      style={{ border: 0 }}
                      className="w-full h-[500px]"
                      allowFullScreen
                    />
                  </div>
                </div>

                <div className="lg:col-span-5 md:col-span-6">
                  <div className="p-6">
                    <h3 className="mb-6 text-2xl leading-normal font-bold">
                      <LanguageTranslation id="contractUs.companyName" />
                    </h3>
                    <p className="font-semibold text-slate-500">Successful Business Partner</p>

                    <div className="flex items-center mt-6">
                      <Icon.Mail className="w-6 h-6 me-4" />
                      <div className="">
                        <h5 className="title font-bold text-lg mb-0">veda@hingom.com</h5>
                      </div>
                    </div>

                    <div className="flex items-center mt-6">
                      <Icon.Phone className="w-6 h-6 me-4" />
                      <div className="">
                        <h5 className="title font-bold text-lg mb-0"> 070-8335-7318</h5>
                      </div>
                    </div>

                    <div className="flex items-center mt-6">
                      <Icon.MapPin className="w-10 h-10 me-4" />
                      <div className="">
                        <h5 className="title font-bold text-lg mb-0">
                          <LanguageTranslation id="contractUs.address" />
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      <Footer />
    </>
  );
}
