import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import image6 from 'assets/images/blog/01.jpg';
import image7 from 'assets/images/blog/02.jpg';
import image8 from 'assets/images/blog/03.jpg';
import LanguageTranslation from './LanguageTranslation';

export default function Blog(props) {
  const blogData = [
    {
      Title: '디자인의 새로운 트렌드: 사용자 정의 앱 디자인의 중요성',
      Description:
        '디지털 혁신의 시대에 우리는 어떻게 앱을 디자인하는가에 대해 새로운 관점을 제시하고 있습니다. 사용자 정의 앱 디자인은 기업과 소비자 간의 상호 작용을 혁신하고 있으며, 이에 따라 IT 기업들은 새로운 트렌드를 따라가기 위해 노력하고 있습니다. 이 기사에서는 사용자 정의 앱 디자인의 중요성과 현재의 IT 산업 동향을 알아봅니다.',
      Image: image6,
      DelayTime: '.3s',
    },
    {
      Title: 'IT 혁신의 핵심: 앱이 IT 세계를 어떻게 변화시키고 있는가',
      Description:
        '앱은 IT 세계에서 혁신의 중심에 서 있습니다. 다양한 산업 분야에서 앱의 역할은 더욱 커져가고 있으며, 기업들은 앱을 통해 비즈니스 프로세스를 최적화하고 고객 경험을 향상시키는 방법을 모색하고 있습니다. 이 기사에서는 현재의 IT 트렌드와 앞으로의 발전 방향을 살펴봅니다.',
      Image: image7,
      DelayTime: '.5s',
    },
    {
      Title: '비즈니스를 위한 지능형 솔루션: 스마트 어플리케이션의 현실적인 이점',
      Description:
        '지능형 어플리케이션은 현대 비즈니스 환경에서 경쟁 우위를 창출하는 핵심적인 도구로 부상하고 있습니다. 데이터 분석, 인공 지능, 머신 러닝 등의 기술을 활용한 스마트 어플리케이션은 기업들에게 효율적인 의사 결정과 혁신적인 서비스 제공의 가능성을 열어주고 있습니다. 이 기사에서는 비즈니스를 위한 스마트 어플리케이션의 현실적인 이점을 살펴봅니다.',
      Image: image8,
      DelayTime: '.7s',
    },
  ];
  return (
    <div className={props.className}>
      <div
        className="grid md:grid-cols-12 grid-cols-1 items-center wow animate__animated animate__fadeInUp"
        data-wow-delay=".1s"
      >
        <div className="md:col-span-6">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Blogs</h6>
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              <LanguageTranslation id="blog.title" /> <br /> News & Blog
            </h3>
          </motion.div>
        </div>
        <div className="md:col-span-6">
          {/* <p className="text-slate-400 max-w-xl">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
        {blogData.map((data, index) => {
          return (
            <motion.div
              key={index}
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: { delay: 0.1 * index, duration: 0.8 },
              }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div
                className="blog md:h-[550px] relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
                data-wow-delay={data.DelayTime}
              >
                <img src={data.Image} alt="" />

                <div className="content p-6">
                  <div className="max-h-36 overflow-hidden">
                    <Link
                      to="/"
                      className="title h5 text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out"
                    >
                      {data.Title}
                    </Link>
                    <p className="text-slate-400 h-[110px] mt-3 overflow-hidden text-ellipsis">
                      {data.Description}
                    </p>
                  </div>
                  <div className="mt-4">
                    <Link
                      to="/"
                      className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                    >
                      <LanguageTranslation id="blog.readMore" />
                      <FaArrowRight className="ms-2 text-[10px]" />
                    </Link>
                  </div>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
