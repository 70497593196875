import Footer from 'component/Footer/footer';
import Navbar from 'component/Navbar/navbar';
import { motion } from 'framer-motion';
import ProductsTab from './ProductsTab';
import LanguageTranslation from '../../component/LanguageTranslation';

export default function ProductsInfo() {
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-32 lg:py-40 bg-[url('assets/images/products/bg_products_title.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black" />
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="mb-6 md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold text-white">
                <LanguageTranslation id="products.title" />
              </h3>

              <p className="font-semibold text-slate-300 text-lg max-w-xl mx-auto">
                <LanguageTranslation id="products.subTitle" />
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      <ProductsTab className="grid lg:w-3/4 mx-auto lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 mt-4 gap-[30px]" />
      <Footer />
    </>
  );
}
