import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    let scrollPosition = 0;

    // Define the URLs where you want to scroll to a different position
    const specialUrls = ['/products/0', '/products/1', '/products/2', '/products/3'];

    // Check if the current pathname is in the specialUrls array
    if (specialUrls.includes(pathname)) {
      // Set the scroll position to 500px from the top
      scrollPosition = 450;
    }
    document.documentElement.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'instant', // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}
