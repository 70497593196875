import 'react-18-image-lightbox/style.css';
import { motion } from 'framer-motion';
import imgBss1 from 'assets/images/products/bss_1.jpg';
import imgBss2 from 'assets/images/products/bss_2.jpg';
import { Link } from 'react-scroll';
import {
  RiExchangeBoxLine,
  RiRemoteControlLine,
  FaUserCheck,
  TbHeartRateMonitor,
  FaUserShield,
  FaMagic,
} from 'assets/icons/icons';
import LanguageTranslation from '../../../component/LanguageTranslation';

export default function BSStationTab(props) {
  const featureData = [
    {
      title: <LanguageTranslation id="products.tab2.info2.data1.title" />,
      desc: <LanguageTranslation id="products.tab2.info2.data1.desc" />,
      icon: RiExchangeBoxLine,
    },
    {
      title: <LanguageTranslation id="products.tab2.info2.data2.title" />,
      desc: <LanguageTranslation id="products.tab2.info2.data2.desc" />,
      icon: TbHeartRateMonitor,
    },
    {
      title: <LanguageTranslation id="products.tab2.info2.data3.title" />,
      desc: <LanguageTranslation id="products.tab2.info2.data3.desc" />,
      icon: RiRemoteControlLine,
    },
    {
      title: <LanguageTranslation id="products.tab2.info2.data4.title" />,
      desc: <LanguageTranslation id="products.tab2.info2.data4.desc" />,
      icon: FaUserCheck,
    },

    {
      title: <LanguageTranslation id="products.tab2.info2.data5.title" />,
      desc: <LanguageTranslation id="products.tab2.info2.data5.desc" />,
      icon: FaUserShield,
    },
    {
      title: <LanguageTranslation id="products.tab2.info2.data6.title" />,
      desc: <LanguageTranslation id="products.tab2.info2.data6.desc" />,
      icon: FaMagic,
    },
  ];

  return (
    <div className="w-full">
      <div className="relative md:mt-24 mt-16">
        <section className="py-20 w-full table relative background-effect bg-[url('assets/images/products/bss_title_bg.jpg')] bg-center bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-slate-900/70" />
          <div className="relative">
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl text-white font-semibold">
                  <LanguageTranslation id="products.tab2.title" />
                </h3>

                <p className="font-semibold text-white/80 max-w-xl mx-auto">
                  <LanguageTranslation id="products.tab2.subTitle" isArray />
                </p>
              </div>
            </motion.div>
          </div>
        </section>
        <section className="relative md:py-24 py-16">
          <div className="container relative">
            <div className="grid md:grid-cols-2 grid-cols-1 items-start gap-[30px]">
              <div
                className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                data-wow-delay=".3s"
              >
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <img src={imgBss1} className="rounded-lg  dark:shadow-gray-800" alt="" />
                  <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                </motion.div>
              </div>
              <div className="section-title mb-8">
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Software</h6>
                  <h4 className="md:text-3xl md:leading-normal text-3xl leading-normal font-semibold mb-4">
                    <LanguageTranslation id="products.tab2.info1.title" isArray />
                  </h4>
                  <p className="font-semibold text-slate-500 max-w-3xl text-lg">
                    <LanguageTranslation id="products.tab2.info1.desc" isArray />
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Key Features</h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab2.info2.title" />
              </h3>

              <p className="font-semibold text-slate-500 max-w-3xl mx-auto text-xl">
                <LanguageTranslation id="products.tab2.info2.desc" />
              </p>
            </motion.div>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {featureData.map((item, index) => {
              const Icons = item.icon;
              return (
                <motion.div
                  key={index}
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: { delay: 0.1 * index, duration: 0.8 },
                  }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <div className="px-6 py-10 min-h-[365px] shadow-lg hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                    <Icons className="h-12 w-12 text-indigo-600" />

                    <div className="content mt-7">
                      <Link to="/" className="title h5 text-lg font-semibold hover:text-indigo-600">
                        {item.title}
                      </Link>
                      <p className="font-semibold text-slate-500 mt-3">{item.desc}</p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
            <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]" />
            <div className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full" />
          </div>
        </div>

        <section className="relative md:py-24 py-16">
          <div className="container relative">
            <div className="grid md:grid-cols-2 grid-cols-1 items-start gap-[30px] md:mt-24 mt-16">
              <div
                className="relative order-2 md:order-1 wow animate__animated animate__fadeInLeft"
                data-wow-delay=".3s"
              >
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <img src={imgBss2} className="rounded-lg  dark:shadow-gray-800" alt="" />
                  <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                </motion.div>
              </div>
              <div className="section-title mb-8 order-1 md:order-2">
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <h6 className="text-indigo-600 text-sm font-bold mb-2">Maintenance</h6>
                  <h4 className="md:text-3xl md:leading-normal text-3xl leading-normal font-semibold mb-4">
                    <LanguageTranslation id="products.tab2.info3.title" isArray />
                  </h4>
                  <p className="text-slate-500 max-w-3xl text-lg" />
                </motion.div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
