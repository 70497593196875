import { Link } from 'react-router-dom';
import Navbar from 'component/Navbar/navbar';
import Footer from 'component/Footer/footer';
import { motion } from 'framer-motion';
import { BsCheckCircle } from 'react-icons/bs';
import {
  MdKeyboardArrowRight,
  RiBracesLine,
  RiCloudWindyLine,
  RiComputerLine,
  RiShieldFlashLine,
} from 'assets/icons/icons';
import LanguageTranslation from 'component/LanguageTranslation';
import imgRemoteControl from 'assets/images/landing/remoteControl.png';
import bss1 from 'assets/images/landing/bss_1.jpg';
import imgPosMain from 'assets/images/landing/pos_main.png';
import ctaBg from 'assets/images/landing/cta-bg.jpg';

export default function AboutUs() {
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-44 bg-[url('assets/images/company/aboutus.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="mb-6 md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold text-white">
                <LanguageTranslation id="aboutUs.title" />
              </h3>

              <p className="font-semibold text-slate-300 text-lg max-w-xl mx-auto">
                <LanguageTranslation id="aboutUs.subTitle" isArray />
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="relative dark:bg-slate-800">
        <div className="container relative md:mt-36 mt-20 ">
          <div
            className="grid grid-cols-1 pb-16 text-center wow animate__animated animate__fadeInUp"
            data-wow-delay=".1s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-base font-semibold mb-2">Who are we</h6>
              <h3 className=" md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold">
                <LanguageTranslation id="aboutUs.info1.title" />
              </h3>
              {/* <p className="text-base font-semibold text-slate-500 max-w-xl mx-auto">
                <LanguageTranslation id="aboutUs.info1.desc" isArray />
              </p> */}
            </motion.div>
          </div>
        </div>

        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-10 min-h-[275px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <RiBracesLine className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-7">
                    <Link to="/" className="title h5 text-lg font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="aboutUs.info1.data1.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="aboutUs.info1.data1.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-10 min-h-[275px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <RiCloudWindyLine className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-7">
                    <Link to="/" className="title h5 text-lg font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="aboutUs.info1.data2.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="aboutUs.info1.data2.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-10 min-h-[275px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <RiShieldFlashLine className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-7">
                    <Link to="/" className="title h5 text-lg font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="aboutUs.info1.data3.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="aboutUs.info1.data3.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-10 min-h-[275px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <RiComputerLine className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-7">
                    <Link to="/" className="title h5 text-lg font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="aboutUs.info1.data4.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="aboutUs.info1.data4.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>

            <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]" />
            <div className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full" />
          </div>
        </div>
      </section>

      <section className="relative md:mt-36 mt-20 bg-white pt-36">
        <div className="container relative">
          <div
            className="grid grid-cols-1 pb-32 text-center wow animate__animated animate__fadeInUp"
            data-wow-delay=".1s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h3 className=" md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold">
                <LanguageTranslation id="aboutUs.business.mainTitle" />
              </h3>
              <p className="text-base font-semibold text-slate-500 max-w-xl mx-auto">
                <LanguageTranslation id="aboutUs.business.mainDesc" />
              </p>
            </motion.div>
          </div>

          <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px] ">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div
                className="relative wow animate__animated animate__fadeInLeft shadow-lg"
                data-wow-delay=".3s"
              >
                <img src={imgRemoteControl} className="rounded-lg dark:shadow-gray-800" alt="" />
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 z-1 rounded-3xl" />
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div
                className="lg:ms-8 wow animate__animated animate__fadeInRight"
                data-wow-delay=".3s"
              >
                <h6 className="text-indigo-600 text-base font-semibold mb-2">DIAMOND Platform</h6>
                <h4 className="mb-4 text-5xl leading-normal font-extrabold">
                  <LanguageTranslation id="aboutUs.business.data1.title" isArray />
                </h4>
                <p className="text-base font-semibold text-slate-500">
                  <LanguageTranslation id="aboutUs.business.data1.desc" />
                </p>
                <ul className="list-none text-slate-500 mt-4">
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data1.listData1" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data1.listData2" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data1.listData3" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data1.listData4" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data1.listData5" />
                  </li>
                </ul>

                <div className="mt-4">
                  <Link
                    to="/products/0"
                    className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                  >
                    <LanguageTranslation id="aboutUs.learnMore" />
                    <MdKeyboardArrowRight className="text-xl ms-1" />
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="container relative md:mt-64 mt-20 ">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div
              className="relative order-1 md:order-2  wow animate__animated animate__fadeInLeft"
              data-wow-delay=".3s"
            >
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <img src={bss1} className="rounded-lg dark:shadow-gray-800 p-6" alt="" />
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 z-1 rounded-3xl" />
              </motion.div>
            </div>

            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div
                className="lg:ms-8 wow animate__animated animate__fadeInRight"
                data-wow-delay=".3s"
              >
                <h6 className="text-indigo-600 text-base font-semibold mb-2">
                  Bettery Swapping Station platform
                </h6>
                <h4 className="mb-4 text-5xl leading-normal font-extrabold">
                  <LanguageTranslation id="aboutUs.business.data2.title" isArray />
                </h4>
                <p className="text-base font-semibold text-slate-500">
                  <LanguageTranslation id="aboutUs.business.data2.desc" />
                </p>
                <ul className="list-none text-slate-500 mt-4">
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data2.listData1" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data2.listData2" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data2.listData3" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data2.listData4" />
                  </li>
                </ul>

                <div className="mt-4">
                  <Link
                    to="/products/1"
                    className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                  >
                    <LanguageTranslation id="aboutUs.learnMore" />
                    <MdKeyboardArrowRight className="text-xl ms-1" />
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="container relative md:mt-64 mt-20 ">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div
                className="relative wow animate__animated animate__fadeInLeft"
                data-wow-delay=".3s"
              >
                <img src={imgPosMain} className="rounded-lg dark:shadow-gray-800 p-6" alt="" />
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 z-1 rounded-3xl" />
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div
                className="lg:ms-8 wow animate__animated animate__fadeInRight"
                data-wow-delay=".3s"
              >
                <h6 className="text-indigo-600 text-base font-semibold mb-2">
                  HINGOM POS Platform
                </h6>
                <h4 className="mb-4 text-5xl leading-normal font-extrabold">
                  <LanguageTranslation id="aboutUs.business.data3.title" isArray />
                </h4>
                <p className="text-base font-semibold text-slate-500">
                  <LanguageTranslation id="aboutUs.business.data3.desc" />
                </p>
                <ul className="list-none text-slate-500 mt-4">
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data3.listData1" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data3.listData2" />
                  </li>
                  <li className="flex items-center">
                    <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                    <LanguageTranslation id="aboutUs.business.data3.listData3" />
                  </li>
                </ul>

                <div className="mt-4">
                  <Link
                    to="/products/3"
                    className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                  >
                    <LanguageTranslation id="aboutUs.learnMore" />
                    <MdKeyboardArrowRight className="text-xl ms-1" />
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-20 bg-white">
        <div className="container relative md:mt-36 mt-20 md:mb-36 mb-20">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative z-1">
              <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                <div className="lg:col-start-2 lg:col-span-10">
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="relative">
                      <img src={ctaBg} className="rounded-md shadow-lg" alt="" />
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className="content md:mt-8">
                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                  <div className="lg:col-start-2 lg:col-span-10">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <div className="grid md:grid-cols-2 grid-cols-1 items-center">
                        <div className="mt-8">
                          <div className="section-title text-base-start">
                            <h6 className="text-white/50 text-lg font-semibold">Recruit</h6>
                            <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-white mt-2">
                              <LanguageTranslation id="aboutUs.recruit.title" />
                            </h3>
                          </div>
                        </div>

                        <div className="mt-8">
                          <div className="section-title text-base-start">
                            <p className="text-white/50 max-w-xl mx-auto mb-2">
                              <LanguageTranslation id="aboutUs.recruit.desc" isArray />
                            </p>
                            <Link to="/recruit" className="text-white inline-flex items-center">
                              <LanguageTranslation id="aboutUs.learnMore" />
                              <MdKeyboardArrowRight className="text-xl ms-1" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-indigo-500 to-indigo-600" />
      </section>

      <Footer />
    </>
  );
}
