import { useEffect, useState } from 'react';
import 'react-18-image-lightbox/style.css';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import DiamondTab from './tab/DiamondTab';
import PosTab from './tab/PosTab';
import ITOutsourcingTab from './tab/ITOutsourcingTab';
import BSStationTab from './tab/BSStationTab';
import LanguageTranslation from '../../component/LanguageTranslation';

// StyledTabs: 커스텀 스타일을 적용한 Tabs 컴포넌트
const StyledTabs = styled(Tabs)(({ theme }) => ({
  // 추가적인 스타일 적용
  width: '100%',
  '.MuiTabs-indicator': {
    backgroundColor: 'unset',
  },
}));

// StyledTab: 커스텀 스타일을 적용한 Tab 컴포넌트
const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'inline-block',
  fontWeight: 'bold',
  borderRadius: '10px 10px 0 0', // Add rounded corners to the top
  marginRight: theme.spacing(2), // Add some right margin for visual separation
  transition: 'border-bottom 0.3s ease-out, color 0.3s ease-out', // Add transitions for a smooth effect

  '&:hover': {},

  '&.Mui-selected': {
    backgroundColor: 'rgb(245, 245, 247)', // Change background color of the selected tab
    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)', // Remove vertical shadow for the selected tab
  },

  '&:not(.Mui-selected)': {
    color: theme.palette.text.secondary, // Change text color of unselected tabs
  },
}));

// StyledContent: 커스텀 스타일을 적용한 컨텐츠 영역
const StyledContent = styled('div')(({ theme }) => ({}));

export default function ProductsTab(props) {
  const routeParams = useParams();

  const { tabIdx } = routeParams;
  const [selectedCategory, setSelectedCategory] = useState(
    parseInt(tabIdx === undefined ? '0' : tabIdx, 10)
  );
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedCategory(parseInt(tabIdx === undefined ? '0' : tabIdx, 10));
  }, [tabIdx]);

  const handleTabChange = (event, value) => {
    setSelectedCategory(value);

    navigate(`/products/${value}`);
  };

  return (
    <section className="relative w-full ">
      <div
        className="w-full filters-group"
        style={{
          // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
          boxShadow: '0px -2px 0px rgba(0, 0, 0, 0.2) inset',
          position: 'sticky',
          top: 88,
          zIndex: 998, // 필요에 따라 조절
          backgroundColor: '#fff', // 배경색 설정
        }}
      >
        <motion.div
          className="flex w-full filters-group-wrap"
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
          viewport={{ once: true, amount: 'some' }}
        >
          <StyledTabs
            value={selectedCategory}
            onChange={handleTabChange}
            variant="fullWidth"
            centered
            scrollButtons="auto"
            className=""
          >
            <StyledTab
              className="inline-block font-semibold text-base mt-2 mx-4 cursor-pointer relative"
              label={<LanguageTranslation id="products.tab1.tabName" />}
            />
            <StyledTab
              className="inline-block font-semibold text-base mt-2 mx-4 cursor-pointer relative"
              label={<LanguageTranslation id="products.tab2.tabName" />}
            />
            <StyledTab
              className="inline-block font-semibold text-base mt-2 mx-4 cursor-pointer relative"
              label={<LanguageTranslation id="products.tab3.tabName" />}
            />
            <StyledTab
              className="inline-block font-semibold text-base mt-2 mx-4 cursor-pointer relative"
              label={<LanguageTranslation id="products.tab4.tabName" />}
            />
          </StyledTabs>
        </motion.div>
      </div>
      <StyledContent className="relative">
        <div className="grid grid-cols-1 items-center gap-[30px]">
          {selectedCategory === 0 && <DiamondTab />}
          {selectedCategory === 1 && <BSStationTab />}
          {selectedCategory === 2 && <ITOutsourcingTab />}
          {selectedCategory === 3 && <PosTab />}
        </div>
      </StyledContent>
    </section>
  );
}
