import Navbar from 'component/Navbar/navbar';
import 'react-18-image-lightbox/style.css';
import Footer from 'component/Footer/footer';
import { motion } from 'framer-motion';
import LanguageTranslation from '../../component/LanguageTranslation';

export default function History() {
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-44 bg-[url('assets/images/company/cta2.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="mb-6 md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold text-white">
                <LanguageTranslation id="history.title" />
              </h3>

              <p className="font-semibold text-slate-300 text-lg max-w-xl mx-auto">
                <LanguageTranslation id="history.subTitle" isArray />
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      <section className="relative md:py-24 py-16 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 mt-8">
            <div className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="md:text-end md:me-8 relative" />

                  <div className="ltr:float-left rtl:float-right text-start ms-8 mt-0 md:mt-0">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2023/06</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info11.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info11.desc" isArray />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="text-start ms-8 relative md:order-2" />

                  <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2023/05</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info10.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info10.desc" />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="md:text-end md:me-8 relative" />

                  <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2021/04</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info9.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info9.desc" />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="text-start ms-8 relative md:order-2" />

                  <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2021/01</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info8.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info8.desc" />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="md:text-end md:me-8 relative" />

                  <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2020/10</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info7.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info7.desc" />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="text-start ms-8 relative md:order-2" />

                  <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2020/08</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info6.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info6.desc" />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="md:text-end md:me-8 relative" />

                  <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2020/07</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info5.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info5.desc" isArray />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="text-start ms-8 relative md:order-2" />

                  <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2020/02</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info4.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info4.desc" />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="md:text-end md:me-8 relative" />

                  <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2019/10</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info3.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500">
                        <LanguageTranslation id="history.info3.desc" isArray />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="text-start ms-8 relative md:order-2" />

                  <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600 text-blue-600">
                        2019/06
                      </h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info2.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500" />
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                <div className="grid md:grid-cols-2">
                  <div className="md:text-end md:me-8 relative" />

                  <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h5 className="my-2 font-semibold  text-lg text-blue-600">2019/05</h5>
                      <h5 className="title mb-1 font-semibold text-lg">
                        <LanguageTranslation id="history.info1.title" />
                      </h5>
                      <p className="mt-3 mb-0 font-semibold text-slate-500" />
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
