import 'react-18-image-lightbox/style.css';
import diamond2 from 'assets/images/products/diamond_2.png';
import diamond3 from 'assets/images/products/diamond_3.png';
import { motion } from 'framer-motion';
import {
  BiSolidMessageSquareCheck,
  TbDeviceMobileCheck,
  MdBrowserUpdated,
  BsCheckCircle,
  BsCollection,
  VscServerProcess,
  LuLayoutDashboard,
  TbDeviceAnalytics,
  PiReadCvLogoBold,
  RiRemoteControlLine,
} from 'assets/icons/icons';
import { Link } from 'react-router-dom';
import { Fa1, Fa2, Fa3, Fa4 } from 'react-icons/fa6';
import imgAdminWebDashboard1 from 'assets/images/products/d_admin_web_dashboard_1.png';
import imgAdminWebDashboard2 from 'assets/images/products/d_admin_web_dashboard_2.png';
import imgAdminWebOta1 from 'assets/images/products/d_admin_web_ota_1.png';
import imgAdminWebLog1 from 'assets/images/products/d_admin_web_log_1.png';
import imgAdminWebRemote1 from 'assets/images/products/d_admin_web_remote_1.png';
import dMobileDashboard1 from 'assets/images/products/d_mobile_dashboard_1.jpg';
import dMobileMap1 from 'assets/images/products/d_mobile_map_1.jpg';
import dMobileLog1 from 'assets/images/products/d_mobile_log_1.jpg';
import dMobileRemote1 from 'assets/images/products/d_mobile_remote_1.jpg';
import LanguageTranslation from '../../../component/LanguageTranslation';

export default function DiamondTab(props) {
  const functionData = [
    {
      icon: BiSolidMessageSquareCheck,
      title: <LanguageTranslation id="products.tab1.info2.data1.title" />,
      desc: <LanguageTranslation id="products.tab1.info2.data1.desc" isArray />,
    },
    {
      icon: BiSolidMessageSquareCheck,
      title: <LanguageTranslation id="products.tab1.info2.data2.title" />,
      desc: <LanguageTranslation id="products.tab1.info2.data2.desc" />,
    },
    {
      icon: BiSolidMessageSquareCheck,
      title: <LanguageTranslation id="products.tab1.info2.data3.title" />,
      desc: <LanguageTranslation id="products.tab1.info2.data3.desc" />,
    },
    {
      icon: BiSolidMessageSquareCheck,
      title: <LanguageTranslation id="products.tab1.info2.data4.title" />,
      desc: <LanguageTranslation id="products.tab1.info2.data4.desc" />,
    },
  ];

  const featureData = [
    {
      title: <LanguageTranslation id="products.tab1.info3.data1.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data1.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data1.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data1.list3" />
          </li>
        </>
      ),
      icon: BsCollection,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data2.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data2.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data2.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data2.list3" />
          </li>
        </>
      ),
      icon: VscServerProcess,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data3.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data3.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data3.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data3.list3" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data3.list4" />
          </li>
        </>
      ),
      icon: LuLayoutDashboard,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data4.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data4.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data4.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data4.list3" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data4.list4" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data4.list5" />
          </li>
        </>
      ),
      icon: TbDeviceAnalytics,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data5.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data5.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data5.list2" />
          </li>
        </>
      ),
      icon: PiReadCvLogoBold,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data6.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data6.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data6.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data6.list3" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data6.list4" />
          </li>
        </>
      ),
      icon: RiRemoteControlLine,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data7.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data7.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data7.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data7.list3" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data7.list4" />
          </li>
        </>
      ),
      icon: MdBrowserUpdated,
    },
    {
      title: <LanguageTranslation id="products.tab1.info3.data8.title" />,
      desc: (
        <>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data8.list1" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data8.list2" />
          </li>
          <li className="mb-1 flex items-center">
            <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
            <LanguageTranslation id="products.tab1.info3.data8.list3" />
          </li>
        </>
      ),
      icon: TbDeviceMobileCheck,
    },
  ];

  return (
    <div className="w-full md:py-24 py-16">
      <div className="relative">
        <section className="py-20 w-full table relative background-effect bg-[url('assets/images/products/diamond_title_bg.png')] bg-center bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-slate-900/70" />
          <div className="relative">
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl text-white font-semibold">
                  <LanguageTranslation id="products.tab1.title" />
                </h3>

                <p className="font-semibold text-white/80 max-w-xl mx-auto">
                  <LanguageTranslation id="products.tab1.subTitle" isArray />
                </p>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
      <div className="container relative md:mt-44 mt-16">
        <div className="grid grid-cols-1 pb-8 items-end">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Software</h6>
            <h3 className="mb-4 max-w-[900px] md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">
              <LanguageTranslation id="products.tab1.info1.desc" isArray />
            </h3>
          </motion.div>
        </div>

        <div className="mt-12">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <img src={diamond2} alt="" />
          </motion.div>
        </div>
      </div>

      <section className="relative dark:bg-slate-800">
        <div className="container relative md:mt-44 mt-20 ">
          <div
            className="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp"
            data-wow-delay=".1s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-base font-semibold mb-2">
                Who do you need it for?
              </h6>
              <h3 className="md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold">
                <LanguageTranslation id="products.tab1.info4.title" />
              </h3>
            </motion.div>
          </div>
        </div>

        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-6 min-h-[264px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <Fa1 className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-6">
                    <Link to="/" className="title text-[28px] font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="products.tab1.info4.data1.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="products.tab1.info4.data1.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-6 min-h-[264px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <Fa2 className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-6">
                    <Link to="/" className="title text-[28px] font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="products.tab1.info4.data2.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="products.tab1.info4.data2.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-6 min-h-[264px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <Fa3 className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-6">
                    <Link to="/" className="title text-[28px] font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="products.tab1.info4.data3.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="products.tab1.info4.data3.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="lg:col-span-6 md:col-span-1">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="px-6 py-6 min-h-[264px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                  <Fa4 className="h-12 w-12 text-indigo-600" />

                  <div className="content mt-6">
                    <Link to="/" className="title text-[28px] font-semibold hover:text-indigo-600">
                      <LanguageTranslation id="products.tab1.info4.data4.title" />
                    </Link>
                    <p className="font-semibold md:leading-normal text-slate-500 mt-3">
                      <LanguageTranslation id="products.tab1.info4.data4.desc" />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>

            <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]" />
            <div className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full" />
          </div>
        </div>
      </section>

      <div className="container relative md:mt-44 mt-24">
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-20 gap-[30px]">
          <div className="lg:col-span-6">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Software Benefits</h6>
              <h3 className="mb-4 md:text-5xl md:leading-normal text-5xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info2.title" />
              </h3>
              <p className="font-semibold text-slate-500 max-w-3xl text-xl ">
                <LanguageTranslation id="products.tab1.info2.desc" />
              </p>
              <div className="lg:me-8 mt-12">
                <img src={diamond3} alt="" />
              </div>
            </motion.div>
          </div>
          <div className="lg:col-span-6">
            <div className="grid grid-cols-1 gap-[30px]">
              {functionData.map((item, index) => {
                const Icons = item.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.2, duration: 0.8 },
                    }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="group flex items-center relative overflow-hidden p-3 rounded-md shadow dark:shadow-gray-800 bg-gray-50 dark:bg-slate-800 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out">
                      <span className="text-indigo-600 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                        <Icons className="h-10 w-10" />
                      </span>
                      <div className="flex-1 ms-3">
                        <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">
                          {item.title}
                        </h5>
                        <p className="font-semibold text-slate-500 leading-tight group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">
                          {item.desc}
                        </p>
                      </div>
                      <div className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out">
                        <Icons className="w-15 h-15" />
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="container relative md:mt-44 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <h6 className="text-indigo-600 text-sm font-bold mb-2">Key Features</h6>
            <h3 className="mb-4 md:text-5xl md:leading-normal text-5xl leading-normal font-semibold">
              <LanguageTranslation id="products.tab1.info3.title" />
            </h3>

            <p className="font-semibold text-slate-500 max-w-3xl mx-auto text-xl">
              <LanguageTranslation id="products.tab1.info3.desc" />
            </p>
          </motion.div>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
          {featureData.map((item, index) => {
            const Icons = item.icon;
            return (
              <div
                key={index}
                className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out"
              >
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: { delay: 0.2, duration: 0.8 },
                  }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <div className="w-16 h-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <Icons className="w-6 h-6" />
                  </div>

                  <div className="content mt-7">
                    <p className="title h5 text-lg font-semibold hover:text-indigo-600">
                      {item.title}
                    </p>
                    <p className="font-semibold text-slate-500 leading-tight mt-3">{item.desc}</p>
                  </div>
                </motion.div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container relative md:mt-44 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <h6 className="text-indigo-600 text-sm font-bold mb-2">Key Screens</h6>
            <h3 className="mb-4 md:text-5xl md:leading-normal text-5xl leading-normal font-semibold">
              <LanguageTranslation id="products.tab1.info5.title" />
            </h3>

            <p className="font-semibold text-slate-500 max-w-3xl mx-auto text-xl">
              <LanguageTranslation id="products.tab1.info5.desc" />
            </p>
          </motion.div>
        </div>
      </div>

      <div className="container relative md:mt-24 mt-24">
        <div className="flex md:grid-cols-2 grid-cols-1 items-center mt-20 gap-[30px]">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <h6 className="text-indigo-600 text-sm font-bold mb-2">Admin Console Web</h6>
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              <LanguageTranslation id="products.tab1.info5.product1.title" />
            </h3>
            <p className="font-semibold text-slate-500 max-w-3xl text-xl">
              <LanguageTranslation id="products.tab1.info5.product1.desc" isArray />
            </p>
          </motion.div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-24 mt-24 gap-[30px]">
          <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img
                src={imgAdminWebDashboard1}
                className="rounded-lg  dark:shadow-gray-800"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div className="lg:me-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product1.advantage1.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product1.advantage1.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage1.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage1.feature2" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage1.feature3" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
          <div
            className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img
                src={imgAdminWebDashboard2}
                className="rounded-lg  dark:shadow-gray-800"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div
            className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product1.advantage2.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product1.advantage2.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage2.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage2.feature2" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage2.feature3" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-24 mt-24 gap-[30px]">
          <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={imgAdminWebOta1} className="rounded-lg  dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div className="lg:me-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product1.advantage3.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product1.advantage3.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage3.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage3.feature2" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage3.feature3" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
          <div
            className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={imgAdminWebLog1} className="rounded-lg  dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div
            className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product1.advantage4.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product1.advantage4.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage4.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage4.feature2" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-24 mt-24 gap-[30px]">
          <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={imgAdminWebRemote1} className="rounded-lg  dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div className="lg:me-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product1.advantage5.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product1.advantage5.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage5.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product1.advantage5.feature2" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container relative md:mt-36 mt-24">
        <div className="flex md:grid-cols-2 grid-cols-1 items-center mt-20 gap-[30px]">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <h6 className="text-indigo-600 text-sm font-bold mb-2">Mobile App</h6>
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              <LanguageTranslation id="products.tab1.info5.product2.title" />
            </h3>
            <p className="font-semibold text-slate-500 max-w-3xl text-xl">
              <LanguageTranslation id="products.tab1.info5.product2.desc" />
            </p>
          </motion.div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-24 mt-24 gap-[30px]">
          <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={dMobileDashboard1} className="rounded-5xl dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div className="lg:me-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product2.advantage1.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product2.advantage1.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage1.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage1.feature2" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />{' '}
                  <LanguageTranslation id="products.tab1.info5.product2.advantage1.feature3" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
          <div
            className="relative flex justify-center order-1 md:order-2 wow animate__animated animate__fadeInLeft"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={dMobileMap1} className="rounded-5xl  dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div
            className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product2.advantage2.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product2.advantage2.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage2.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage2.feature2" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage2.feature3" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-24 mt-24 gap-[30px]">
          <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={dMobileLog1} className="rounded-5xl  dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div className="lg:me-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product2.advantage3.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product2.advantage3.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage3.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage3.feature2" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container relative md:mt-36 mt-24">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
          <div
            className="relative flex justify-center order-1 md:order-2 wow animate__animated animate__fadeInLeft"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <img src={dMobileRemote1} className="rounded-5xl  dark:shadow-gray-800" alt="" />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
            </motion.div>
          </div>

          <div
            className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
            data-wow-delay=".3s"
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
              <h4 className="mb-4 text-2xl leading-normal font-semibold">
                <LanguageTranslation id="products.tab1.info5.product2.advantage4.title" />
              </h4>
              <p className="font-semibold text-slate-500">
                <LanguageTranslation id="products.tab1.info5.product2.advantage4.desc" />
              </p>
              <ul className="list-none font-semibold text-slate-500 mt-4">
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage4.feature1" />
                </li>
                <li className="mb-1 flex items-center">
                  <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                  <LanguageTranslation id="products.tab1.info5.product2.advantage4.feature2" />
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
