import { useTranslation } from 'react-i18next';
import { Button, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useState } from 'react';

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [menu, setMenu] = useState(null);
  const languages = [
    { id: 'ko', title: '한국어', flag: 'KR' },
    { id: 'en', title: 'English', flag: 'US' },
  ];

  // 현재 지정된 언어 코드
  const currentLanguage = languages.find((lng) => lng.id === i18n.language);

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };
  const changeLanguage = (language) => {
    i18n
      .changeLanguage(language)
      .then(() => {})
      .catch((err) => console.error('Error while changing language', err));
  };

  function handleLanguageChange(lng) {
    changeLanguage(lng.id);

    langMenuClose();
  }

  return (
    <div className="languageSwitch">
      <Button className="h-40 w-36" onClick={langMenuClick}>
        <img
          className="min-w-20"
          src={`/assets/flags/${currentLanguage.flag}.svg`}
          alt={currentLanguage.title}
        />

        <Typography className="mx-1 font-medium text-sm uppercase" color="text.secondary">
          {currentLanguage.title}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableScrollLock // 스크롤 잠금 비활성화
        classes={{}}
      >
        {languages.map((lng) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="min-w-40">
              <img className="min-w-20" src={`/assets/flags/${lng.flag}.svg`} alt={lng.title} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography style={{ fontSize: '0.9rem' }}>{lng.title}</Typography>}
            />
          </MenuItem>
        ))}
      </Popover>
    </div>
  );
};

export default LanguageSwitch;
