import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

const LanguageTranslation = ({ id, isArray }) => {
  const { t } = useTranslation();

  if (isArray) {
    return (
      <>
        {t(id, { returnObjects: true }).map((part, i) => (
          <Fragment key={i}>
            {part}
            <br />
          </Fragment>
        ))}
      </>
    );
  }
  return <>{t(`${id}`)}</>;
};

export default LanguageTranslation;
