import { Link } from 'react-router-dom';

import background from 'assets/images/landing/1.jpg';

import { motion } from 'framer-motion';

import Navbar from 'component/Navbar/navbar';
import Footer from 'component/Footer/footer';
import Blog from 'component/blog';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import * as Icon from 'react-feather';
import LanguageTranslation from 'component/LanguageTranslation';
import itOutsourcingInfo1 from 'assets/images/landing/it_outsourcing_1.jpg';
import business01 from 'assets/images/landing/business_1.jpg';
import business02 from 'assets/images/landing/business_2.jpg';
import business03 from 'assets/images/landing/business_3.jpg';
import bssInfo2 from 'assets/images/landing/bss_info_2.jpg';
import diamondInfo1 from 'assets/images/landing/diamond_info_1.jpg';
import bssBg1 from 'assets/images/landing/bss_title_bg.jpg';

export default function IndexCorporate() {
  const cardData = [
    {
      icon: Icon.Cast,
      title: <LanguageTranslation id="landing.card1.title" />,
      desc: <LanguageTranslation id="landing.card1.desc" isArray />,
      link: '/products/0',
    },
    {
      icon: Icon.BatteryCharging,
      title: <LanguageTranslation id="landing.card2.title" />,
      desc: <LanguageTranslation id="landing.card2.desc" isArray />,
      link: '/products/1',
    },
    {
      icon: Icon.Monitor,
      title: <LanguageTranslation id="landing.card3.title" />,
      desc: <LanguageTranslation id="landing.card3.desc" isArray />,
      link: '/products/3',
    },
    /* {
      icon: Icon.Battery,
      title: '배터리 교환 서비스 플랫폼',
      desc: (
        <>
          배터리 교환 스테이션 소프트웨어 및<br />
          현장 장비 진단 및 모니터링 플랫폼 DIAMOND를
          <br />
          연동하여 BSS 플랫폼을 제공합니다.
        </>
      ),
    }, */
  ];

  const companyInfoData = [
    {
      image: business01,
      title: <LanguageTranslation id="landing.info4.subData1.title" />,
      desc: <LanguageTranslation id="landing.info4.subData1.desc" />,
      icon: Icon.Code,
    },
    {
      image: business02,
      title: <LanguageTranslation id="landing.info4.subData2.title" />,
      desc: <LanguageTranslation id="landing.info4.subData2.desc" isArray />,
      icon: Icon.GitMerge,
    },
    {
      image: business03,
      title: <LanguageTranslation id="landing.info4.subData3.title" />,
      desc: <LanguageTranslation id="landing.info4.subData3.desc" />,
      icon: Icon.Sun,
    },
  ];

  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="swiper-slider-hero relative block h-screen" id="home">
        <div className="swiper-container swiper-container-initialized swiper-container-horizontal h-full">
          <Swiper
            className="swiper-wrapper"
            spaceBetween={50}
            slidesPerView={1}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            navigation
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            <SwiperSlide>
              <div
                className="swiper-slide flex items-center overflow-hidden"
                style={{ backgroundImage: `url(${background})` }}
              >
                <div className="slide-inner slide-bg-image flex items-center bg-center w-full">
                  <div className="absolute inset-0 bg-black/70" />
                  <div className="container relative">
                    <div className="grid grid-cols-1">
                      <div className="text-center">
                        <h1 className="font-semibold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                          <LanguageTranslation id="landing.slide1.title" />
                        </h1>
                        <p className="text-white/70 text-lg font-semibold max-w-2xl mx-auto">
                          <LanguageTranslation id="landing.slide1.desc" isArray />
                        </p>

                        <div className="mt-6">
                          <Link
                            to="/products/0"
                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                          >
                            Get Started
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div
                className="swiper-slide flex items-center overflow-hidden bg-cover"
                style={{ backgroundImage: `url(${imgPostBg})` }}
              >
                <div className="slide-inner slide-bg-image flex items-center bg-center w-full">
                  <div className="absolute inset-0 bg-black/70" />
                  <div className="container relative">
                    <div className="grid grid-cols-1">
                      <div className="text-center">
                        <h1 className="font-semibold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                          <LanguageTranslation id="landing.slide2.title" />
                        </h1>
                        <p className="text-white/70 text-lg font-semibold max-w-2xl mx-auto">
                          <LanguageTranslation id="landing.slide2.desc" isArray />
                        </p>

                        <div className="mt-6">
                          <Link
                            to="/products/3"
                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                          >
                            Get Started
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div
                className="swiper-slide flex items-center overflow-hidden bg-cover"
                style={{ backgroundImage: `url(${bssBg1})` }}
              >
                <div className="slide-inner slide-bg-image flex items-center bg-center w-full">
                  <div className="absolute inset-0 bg-black/70" />
                  <div className="container relative">
                    <div className="grid grid-cols-1">
                      <div className="text-center">
                        <h1 className="font-semibold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                          <LanguageTranslation id="landing.slide3.title" />
                        </h1>
                        <p className="text-white/70 text-lg font-semibold max-w-2xl mx-auto">
                          <LanguageTranslation id="landing.slide3.desc" isArray />
                        </p>
                        <div className="mt-6">
                          <Link
                            to="/products/1"
                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                          >
                            Get Started
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="relative md:py-36 py-16 ">
        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="relative" data-wow-delay=".5s">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <img src={diamondInfo1} className="rounded-lg dark:shadow-gray-800" alt="" />
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[545px] w-[545px] bg-indigo-600/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl" />
              </motion.div>
            </div>

            <div className="">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="lg:ms-5">
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                    <LanguageTranslation id="landing.info1.title" />
                  </h3>

                  <p className="font-semibold text-slate-500 max-w-full text-xl">
                    <LanguageTranslation id="landing.info1.subTitle" isArray />
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-36 py-16 bg-white">
        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="relative order-1 md:order-2" data-wow-delay=".5s">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <img src={itOutsourcingInfo1} className="rounded-lg dark:shadow-gray-800" alt="" />
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[545px] w-[545px] bg-indigo-600/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl" />
              </motion.div>
            </div>

            <div className="lg:me-8 order-2 md:order-1" data-wow-delay=".5s">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="lg:ms-5">
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                    <LanguageTranslation id="landing.info6.title" />
                  </h3>

                  <p className="font-semibold text-slate-500 max-w-full text-xl">
                    <LanguageTranslation id="landing.info6.subTitle" isArray />
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-36 py-16 ">
        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="relative" data-wow-delay=".5s">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <img src={bssInfo2} className="rounded-lg dark:shadow-gray-800" alt="" />
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[545px] w-[545px] bg-indigo-600/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl" />
              </motion.div>
            </div>

            <div className="">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <div className="lg:ms-5">
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                    <LanguageTranslation id="landing.info5.title" />
                  </h3>

                  <p className="font-semibold text-slate-500 max-w-full text-xl">
                    <LanguageTranslation id="landing.info5.subTitle" isArray />
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 w-full table relative bg-[url('assets/images/company/bg01.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-slate-900/70" />
        <div className="container relative">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 text-center">
              <h3 className="mb-4 md:text-3xl text-3xl text-white font-semibold">
                <LanguageTranslation id="landing.info2.title" />
              </h3>

              <p className="font-semibold text-white/80 max-w-xl mx-auto text-xl">
                <LanguageTranslation id="landing.info2.subTitle" isArray />
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="relative md:py-36 py-16 bg-white">
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-center">
              <div className="md:col-span-6">
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                  <LanguageTranslation id="landing.info4.title" />
                </h3>
              </div>

              <div className="md:col-span-6">
                <p className="font-semibold text-slate-300 max-w-xl" />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 mt-8 gap-[30px]">
              {companyInfoData.map((item, index) => {
                const Icons = item.icon;
                return (
                  <div
                    key={index}
                    className="group w-full h-full rounded-md dark:shadow-gray-800 relative overflow-hidden"
                  >
                    <img src={item.image} className="w-full h-full" alt="" />
                    <div className="absolute inset-0 bg-gradient-to-t to-transparent via-slate-900/60 group-hover:via-slate-900/40 from-slate-900 top-3/4 group-hover:top-0 transition-all duration-500" />
                    <div className="absolute bottom-0 mx-auto start-0 end-0 group-hover:bottom-0 transition-all duration-500 px-6 pb-6 text-center">
                      <Icons className="h-10 w-10 text-white opacity-0 group-hover:opacity-100 transition-all duration-500 mx-auto" />

                      <div className="mt-6">
                        <Link
                          to="/"
                          className="text-xl font-semibold text-white transition-all duration-500"
                        >
                          {item.title}
                        </Link>

                        <p className="font-semibold text-white/50 hidden group-hover:block transition-all duration-500 ease-in-out mt-4">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <Blog className="container relative " />
      </section>
      <Footer />
    </>
  );
}
