import { Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import LandingPage from './pages/landing/LandingPage';
import Navbar from './component/Navbar/navbar';
import AboutUs from './pages/company/AboutUs';
import History from './pages/company/History';
import IpRights from './pages/company/IpRights';
import Contact from './pages/contact/Contact';
import Recruit from './pages/company/Recruit';
import ProductsInfo from './pages/products/ProductsInfo';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/landing" />} />
      <Route path="/index" element={<LandingPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/navbar" element={<Navbar />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/history" element={<History />} />
      <Route path="/ipRights" element={<IpRights />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/recruit" element={<Recruit />} />
      <Route path="/products" element={<ProductsInfo />} />
      <Route path="/products/:tabIdx" element={<ProductsInfo />} />
    </Routes>
  );
}
