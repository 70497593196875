import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import Navbar from 'component/Navbar/navbar';
import Footer from 'component/Footer/footer';
import lifeCoach from 'assets/images/recruit/life-coach.png';
import meditation from 'assets/images/recruit/meditation.png';
import nutrition from 'assets/images/recruit/nutrition.png';
import religious from 'assets/images/recruit/religious.png';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  MdFamilyRestroom,
  MdHealthAndSafety,
  PiAirplaneTakeoff,
  TbAward,
} from 'assets/icons/icons';
import { motion } from 'framer-motion';
import LanguageTranslation from '../../component/LanguageTranslation';

export default function Recruit() {
  const talentedPerson = [
    {
      image: lifeCoach,
      title: <LanguageTranslation id="recruit.info1.data1.title" />,
      desc: <LanguageTranslation id="recruit.info1.data1.desc" />,
    },
    {
      image: religious,
      title: <LanguageTranslation id="recruit.info1.data2.title" />,
      desc: <LanguageTranslation id="recruit.info1.data2.desc" />,
    },
    {
      image: nutrition,
      title: <LanguageTranslation id="recruit.info1.data3.title" />,
      desc: <LanguageTranslation id="recruit.info1.data3.desc" />,
    },
    {
      image: meditation,
      title: <LanguageTranslation id="recruit.info1.data4.title" />,
      desc: <LanguageTranslation id="recruit.info1.data4.desc" />,
    },
  ];

  const benefitData = [
    {
      icon: TbAward,
      title: <LanguageTranslation id="recruit.info3.data1.title" />,
      desc: <LanguageTranslation id="recruit.info3.data1.desc" />,
    },
    {
      icon: MdHealthAndSafety,
      title: <LanguageTranslation id="recruit.info3.data2.title" />,
      desc: <LanguageTranslation id="recruit.info3.data2.desc" />,
    },
    {
      icon: PiAirplaneTakeoff,
      title: <LanguageTranslation id="recruit.info3.data3.title" />,
      desc: <LanguageTranslation id="recruit.info3.data3.desc" />,
    },
    {
      icon: MdFamilyRestroom,
      title: <LanguageTranslation id="recruit.info3.data4.title" />,
      desc: <LanguageTranslation id="recruit.info3.data4.desc" />,
    },
  ];

  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-44 bg-[url('assets/images/company/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h3 className="mb-6 md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold text-white">
                <LanguageTranslation id="recruit.title" />
              </h3>

              <p className="font-semibold text-slate-300 text-lg max-w-xl mx-auto">
                <LanguageTranslation id="recruit.subTitle" />
              </p>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                <LanguageTranslation id="recruit.info1.title" />
              </h3>

              <p className="font-semibold text-slate-500 max-w-xl mx-auto">
                <LanguageTranslation id="recruit.info1.desc" />
              </p>
            </motion.div>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[15px]">
            {talentedPerson.map((item, index) => {
              return (
                <div
                  key={index}
                  className="group relative lg:p-6 transition-all shadow-lg duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center"
                >
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.1 * index, duration: 0.8 },
                    }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="relative overflow-hidden text-transparent -m-3">
                      <Icon.Hexagon className="h-32 w-32 fill-indigo-600/5 mx-auto" />
                      <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                        <img src={item.image} className="w-14 h-14" alt="" />
                      </div>
                    </div>

                    <div className="mt-6">
                      <Link
                        to="/"
                        className="text-xl font-semibold transition-all duration-500 ease-in-out hover:text-indigo-600"
                      >
                        {item.title}
                      </Link>
                      <p className="font-semibold text-slate-500 transition-all duration-500 ease-in-out mt-3">
                        {item.desc}
                      </p>
                    </div>
                  </motion.div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                RECRUITMENT PROCESS
              </h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                <LanguageTranslation id="recruit.info2.title" />
              </h3>

              <p className="font-semibold text-slate-500 max-w-xl mx-auto">
                <LanguageTranslation id="recruit.info2.desc" />
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid md:grid-cols-3 grid-cols-1 bg-white shadow-lg shadow-gray-300 rounded-lg">
              <div className="relative p-6 md:p-8">
                <i className="mdi mdi-numeric-1-box bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]" />

                <h5 className="text-xl font-semibold my-5">
                  <LanguageTranslation id="recruit.info2.data1.title" />
                </h5>

                <p className="font-semibold text-slate-500">
                  <LanguageTranslation id="recruit.info2.data1.desc" />
                </p>
                <MdKeyboardArrowRight className="h-8 w-8 p-1 bg-indigo-600 text-white rounded-full flex justify-center items-center absolute md:top-2/4 md:-translate-y-2/4 -bottom-4 md:-end-0 end-2/4 ltr:translate-x-2/4 rtl:-translate-x-2/4 rtl:rotate-180 z-1" />
              </div>

              <div className="relative p-6 md:p-8">
                <i className="mdi mdi-numeric-2-box bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]" />

                <h5 className="text-xl font-semibold my-5">
                  <LanguageTranslation id="recruit.info2.data2.title" />
                </h5>

                <p className="font-semibold text-slate-500">
                  <LanguageTranslation id="recruit.info2.data2.desc" />
                </p>
                <MdKeyboardArrowRight className="h-8 w-8 p-1 bg-indigo-600 text-white rounded-full flex justify-center items-center absolute md:top-2/4 md:-translate-y-2/4 -bottom-4 md:-end-0 end-2/4 ltr:translate-x-2/4 rtl:-translate-x-2/4 rtl:rotate-180 z-1" />
              </div>
              <div className="relative p-6 md:p-8">
                <i className="mdi mdi-numeric-3-box bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]" />

                <h5 className="text-xl font-semibold my-5">
                  <LanguageTranslation id="recruit.info2.data3.title" />
                </h5>

                <p className="font-semibold text-slate-500">
                  <LanguageTranslation id="recruit.info2.data3.desc" />
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">WELFARE BENEFITS</h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                <LanguageTranslation id="recruit.info3.title" />
              </h3>

              <p className="font-semibold text-slate-500 max-w-xl mx-auto">
                <LanguageTranslation id="recruit.info3.desc" />
              </p>
            </motion.div>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {benefitData.map((item, index) => {
              const Icons = item.icon;
              return (
                <motion.div
                  key={index}
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: { delay: 0.1 * index, duration: 0.8 },
                  }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <div className="group p-6 rounded-lg shadow-lg dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 duration-500">
                    <div className="w-16 h-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 duration-500">
                      <Icons />
                    </div>

                    <div className="content mt-7">
                      <Link
                        to="/page-services"
                        className="title h5 text-lg font-semibold hover:text-indigo-600"
                      >
                        {item.title}
                      </Link>
                      <p className="font-semibold text-slate-500 mt-3">{item.desc}</p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                Submit Job Application
              </h6>
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                <LanguageTranslation id="recruit.info4.title" />
              </h3>

              <p className="font-semibold text-slate-500 max-w-xl mx-auto">
                <LanguageTranslation id="recruit.info4.desc" />
              </p>
            </motion.div>
          </div>
          <div className="mt-6 text-center">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <Link
                to="/recruitDetail"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-transparent hover:bg-indigo-600 border border-indigo-600 text-indigo-600 hover:text-white rounded-md"
              >
                <LanguageTranslation id="recruit.apply" />
              </Link>
            </motion.div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
