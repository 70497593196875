import patents1 from 'assets/images/ip/patents_1.png';
import ciBlue from 'assets/images/ip/ci_hingom_blue.png';
import ciBlueWidth from 'assets/images/ip/ci_hingom_blue_width.png';
import ciBlueHeight from 'assets/images/ip/ci_hingom_blue_height.png';
import ciWhite from 'assets/images/ip/ci_hingom_white.png';
import ciWhiteWidth from 'assets/images/ip/ci_hingom_white_width.png';
import ciWhiteHeight from 'assets/images/ip/ci_hingom_white_height.png';

import biWaveBlueHeight from 'assets/images/ip/bi_h_wave_blue_height.png';
import biWaveBlueWidth from 'assets/images/ip/bi_h_wave_blue_width.png';
import biWaveWhiteHeight from 'assets/images/ip/bi_h_wave_white_height.png';
import biWaveWhiteWidth from 'assets/images/ip/bi_h_wave_white_width.png';
import biPosBlueHeight from 'assets/images/ip/bi_h_pos_blue_height.png';
import biPosBlueWidth from 'assets/images/ip/bi_h_pos_blue_width.png';
import biPosWhiteHeight from 'assets/images/ip/bi_h_pos_white_height.png';
import biPosWhiteWidth from 'assets/images/ip/bi_h_pos_white_width.png';
import biStoreBlueHeight from 'assets/images/ip/bi_h_store_blue_height.png';
import biStoreBlueWidth from 'assets/images/ip/bi_h_store_blue_width.png';
import biStoreWhiteHeight from 'assets/images/ip/bi_h_store_white_height.png';
import biStoreWhiteWidth from 'assets/images/ip/bi_h_store_white_width.png';
import biOrderBlueHeight from 'assets/images/ip/bi_h_order_blue_height.png';
import biOrderBlueWidth from 'assets/images/ip/bi_h_order_blue_width.png';
import biOrderWhiteHeight from 'assets/images/ip/bi_h_order_white_height.png';
import biOrderWhiteWidth from 'assets/images/ip/bi_h_order_white_width.png';
import biDiamondBlueOrigin from 'assets/images/ip/bi_diamond_blue_origin.png';
import biDiamondBlue from 'assets/images/ip/bi_diamond_blue.png';
import biDiamondWhite from 'assets/images/ip/bi_diamond_white.png';
import Navbar from 'component/Navbar/navbar';
import Footer from 'component/Footer/footer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { motion } from 'framer-motion';
import LanguageTranslation from '../../component/LanguageTranslation';

export default function IpRights() {
  const ciData = [
    {
      image: ciBlue,
      desc: <LanguageTranslation id="ipRights.info1.data1.desc" />,
    },
    {
      image: ciBlueWidth,
      desc: <LanguageTranslation id="ipRights.info1.data2.desc" />,
    },
    {
      image: ciBlueHeight,
      desc: <LanguageTranslation id="ipRights.info1.data3.desc" />,
    },
    {
      image: ciWhite,
      desc: <LanguageTranslation id="ipRights.info1.data4.desc" />,
    },
    {
      image: ciWhiteWidth,
      desc: <LanguageTranslation id="ipRights.info1.data5.desc" />,
    },
    {
      image: ciWhiteHeight,
      desc: <LanguageTranslation id="ipRights.info1.data6.desc" />,
    },
  ];

  const biData = [
    {
      image: biWaveBlueHeight,
      desc: <LanguageTranslation id="ipRights.info2.data1.desc" />,
    },
    {
      image: biWaveBlueWidth,
      desc: <LanguageTranslation id="ipRights.info2.data2.desc" />,
    },
    {
      image: biWaveWhiteHeight,
      desc: <LanguageTranslation id="ipRights.info2.data3.desc" />,
    },
    {
      image: biWaveWhiteWidth,
      desc: <LanguageTranslation id="ipRights.info2.data4.desc" />,
    },
    {
      image: biPosBlueHeight,
      desc: <LanguageTranslation id="ipRights.info2.data5.desc" />,
    },
    {
      image: biPosBlueWidth,
      desc: <LanguageTranslation id="ipRights.info2.data6.desc" />,
    },
    {
      image: biPosWhiteHeight,
      desc: <LanguageTranslation id="ipRights.info2.data7.desc" />,
    },
    {
      image: biPosWhiteWidth,
      desc: <LanguageTranslation id="ipRights.info2.data8.desc" />,
    },
    {
      image: biStoreBlueHeight,
      desc: <LanguageTranslation id="ipRights.info2.data9.desc" />,
    },
    {
      image: biStoreBlueWidth,
      desc: <LanguageTranslation id="ipRights.info2.data10.desc" />,
    },
    {
      image: biStoreWhiteHeight,
      desc: <LanguageTranslation id="ipRights.info2.data11.desc" />,
    },
    {
      image: biStoreWhiteWidth,
      desc: <LanguageTranslation id="ipRights.info2.data12.desc" />,
    },
    {
      image: biOrderBlueHeight,
      desc: <LanguageTranslation id="ipRights.info2.data13.desc" />,
    },
    {
      image: biOrderBlueWidth,
      desc: <LanguageTranslation id="ipRights.info2.data14.desc" />,
    },
    {
      image: biOrderWhiteHeight,
      desc: <LanguageTranslation id="ipRights.info2.data15.desc" />,
    },
    {
      image: biOrderWhiteWidth,
      desc: <LanguageTranslation id="ipRights.info2.data16.desc" />,
    },
    {
      image: biDiamondBlueOrigin,
      desc: <LanguageTranslation id="ipRights.info2.data17.desc" />,
    },
    {
      image: biDiamondBlue,
      desc: <LanguageTranslation id="ipRights.info2.data18.desc" />,
    },
    {
      image: biDiamondWhite,
      desc: <LanguageTranslation id="ipRights.info2.data19.desc" />,
    },
  ];

  const patentsData = [
    {
      image: patents1,
      desc: <LanguageTranslation id="ipRights.info3.data1.desc" />,
    },
  ];
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-44 bg-[url('assets/images/company/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container relative">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="mb-6 md:text-5xl text-5xl md:leading-normal leading-normal font-extrabold text-white">
                <LanguageTranslation id="ipRights.title" />
              </h3>

              <p className="font-semibold text-slate-300 text-lg max-w-xl mx-auto">
                <LanguageTranslation id="ipRights.subTitle" />
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="section-title">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <h6 className="text-indigo-600 text-sm font-bold mb-2">Corporate Identity</h6>
                <h4 className="md:text-3xl md:leading-normal text-3xl leading-normal font-semibold mb-4">
                  <LanguageTranslation id="ipRights.info1.title" />
                </h4>
                <p className="font-semibold text-slate-500 mb-0 text-xl">
                  <LanguageTranslation id="ipRights.info1.desc" isArray />
                </p>
              </motion.div>
            </div>
          </div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
              <Masonry columnsCount={3}>
                {ciData.map((item, index) => {
                  return (
                    <div key={index} className="picture-item p-4 rounded-md">
                      <div className="flex justify-center items-center min-h-[365px] p-5 rounded-md bg-[url('assets/images/ip/bg_plaid.jpg')] bg-cover">
                        <img src={item.image} className="w-full rounded-t-md " alt="" />
                      </div>

                      <div className="pt-4 px-3">
                        <span className="font-semibold text-slate-500">{item.desc}</span>
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </motion.div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="section-title mb-8">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <h6 className="text-indigo-600 text-sm font-bold mb-2">Brand Identity</h6>
                <h4 className="md:text-3xl md:leading-normal text-3xl leading-normal font-semibold mb-4">
                  <LanguageTranslation id="ipRights.info2.title" />
                </h4>
                <p className="font-semibold text-slate-500 mb-0 text-xl">
                  <LanguageTranslation id="ipRights.info2.desc" isArray />
                </p>
              </motion.div>
            </div>
          </div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}>
              <Masonry columnsCount={4}>
                {biData.map((item, index) => {
                  return (
                    <div key={index} className="picture-item p-4 rounded-md">
                      <div className="flex justify-center items-center min-h-[264px] p-5 rounded-md bg-[url('assets/images/ip/bg_plaid.jpg')] bg-cover ">
                        <img src={item.image} className="w-full rounded-t-md" alt="" />
                      </div>

                      <div className="pt-4 px-3">
                        <span className="font-semibold text-slate-500">{item.desc}</span>
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </motion.div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="section-title mb-8">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <h6 className="text-indigo-600 text-sm font-bold mb-2">Patents</h6>
                <h4 className="md:text-3xl md:leading-normal text-3xl leading-normal font-semibold mb-4">
                  <LanguageTranslation id="ipRights.info3.title" />
                </h4>
                <p className="font-semibold text-slate-500 mb-0 text-xl">
                  <LanguageTranslation id="ipRights.info3.desc" isArray />
                </p>
              </motion.div>
            </div>
          </div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
            viewport={{ once: true, amount: 'some' }}
          >
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
              <Masonry columnsCount={3}>
                {patentsData.map((item, index) => {
                  return (
                    <div key={index} className="picture-item p-4 rounded-md">
                      <div className="">
                        <div className="relative">
                          <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                            <img src={item.image} className="rounded-t-md shadow w-full " alt="" />
                          </div>
                        </div>

                        <div className="pt-4 px-3">
                          <span className="font-semibold text-slate-500">{item.desc}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
}
