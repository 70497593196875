import 'react-18-image-lightbox/style.css';
import imgPosHWave from 'assets/images/products/p_h_wave.png';
import imgPosHWave1 from 'assets/images/products/p_h_wave_1.png';
import imgPosHWave3 from 'assets/images/products/p_h_wave_3.png';
import imgPosHWave4 from 'assets/images/products/p_h_wave_4.png';
import imgPosHPos1 from 'assets/images/products/p_h_pos_1.jpg';
import imgPosHPos2 from 'assets/images/products/p_h_pos_2.jpg';
import imgPosHPos3 from 'assets/images/products/p_h_pos_3.png';
import imgPosHPos4 from 'assets/images/products/p_h_pos_4.png';
import imgPosHPos5 from 'assets/images/products/p_h_pos_5.png';
import imgPosHPos6 from 'assets/images/products/p_h_pos_6.png';
import imgPosHStore from 'assets/images/products/p_h_store.png';
import imgPosHStore1 from 'assets/images/products/p_h_store_1.svg';
import imgPosHStore2 from 'assets/images/products/p_h_store_2.svg';
import imgPosHStore3 from 'assets/images/products/p_h_store_3.png';
import imgPosHStore4 from 'assets/images/products/p_h_store_4.png';
import imgPosHOrder from 'assets/images/products/p_h_order.png';
import imgPosHOrder1 from 'assets/images/products/p_h_order_1.svg';
import { motion } from 'framer-motion';
import { BsCheckCircle } from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';
import LanguageTranslation from '../../../component/LanguageTranslation';

export default function PosTab(props) {
  const [activeButton, setActiveButton] = useState(0);
  const targetRefs = {
    0: useRef(null),
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    // 추가적인 탭이 있다면 여기에 계속 추가하세요.
  };

  const headerHeight = 500; // 헤더의 높이를 고려해야 합니다.

  const handleTabClick = (index) => {
    setActiveButton(index);
    const targetElement = targetRefs[index].current;

    window.scrollTo({
      top: targetElement.offsetTop + headerHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const handleScroll = () => {
      // targetRefs 배열을 순회하면서 각 요소에 대한 작업 수행
      Object.keys(targetRefs).forEach((index) => {
        const targetRef = targetRefs[index];
        const targetElement = targetRef.current;

        // targetElement의 뷰포트 상태 확인
        const targetRect = targetElement.getBoundingClientRect();
        const isInViewport =
          Math.abs(targetRect.top) <= window.innerHeight && targetRect.bottom >= window.innerHeight;

        /* console.log(
          `index : ${index}`,
          `top : ${Math.abs(targetRect.top)}`,
          `bottom : ${targetRect.bottom}`,
          `view : ${window.innerHeight}`
        ); */
        // 각 targetElement에 따른 작업 수행
        if (isInViewport) {
          setActiveButton(Number(index));
          // console.log(`Target element ${Number(index)} is in the viewport!`);
        } else {
          // console.log(`Target element ${index} is out of the viewport!`);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 unmount될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="w-full">
      <div className="relative md:mt-24 mt-16">
        <section className="py-20 w-full table relative bg-[url('assets/images/products/pos_bg.png')] bg-center bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-slate-900/70" />
          <div className="relative">
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl text-white font-semibold">
                  <LanguageTranslation id="products.tab4.title" />
                </h3>

                <p className="font-semibold text-white/80 max-w-2xl mx-auto">
                  <LanguageTranslation id="products.tab4.subTitle" isArray />
                </p>
              </div>
            </motion.div>
          </div>
        </section>

        <div className="grid md:grid-cols-6 grid-cols-1 gap-[30px]">
          <div className="mt-20 ml-20">
            <div className="sticky top-40">
              <motion.div
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1, transition: { delay: 0.2, duration: 0.8 } }}
                viewport={{ once: true, amount: 'some' }}
              >
                <ul
                  className="flex-column text-center p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li role="presentation">
                    <button
                      className={`${
                        activeButton === 0 ? 'text-white bg-indigo-600 hover:text-white' : ''
                      } px-4 py-2 text-base font-semibold rounded-md w-full hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="tab-0"
                      data-tabs-target="#tab-0"
                      type="button"
                      role="tab"
                      aria-controls="tab-0"
                      aria-selected={activeButton === 0}
                      onClick={() => handleTabClick(0)}
                    >
                      h.WAVE
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={`${
                        activeButton === 1 ? 'text-white bg-indigo-600 hover:text-white' : ''
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="tab-1"
                      data-tabs-target="#tab-1"
                      type="button"
                      role="tab"
                      aria-controls="tab-1"
                      aria-selected={activeButton === 1}
                      onClick={() => handleTabClick(1)}
                    >
                      h.POS
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={`${
                        activeButton === 2 ? 'text-white bg-indigo-600 hover:text-white' : ''
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="tab-2"
                      data-tabs-target="#tab-0"
                      type="button"
                      role="tab"
                      aria-controls="tab-2"
                      aria-selected={activeButton === 2}
                      onClick={() => handleTabClick(2)}
                    >
                      h.STORE
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={`${
                        activeButton === 3 ? 'text-white bg-indigo-600 hover:text-white' : ''
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="tab-3"
                      data-tabs-target="#tab-3"
                      type="button"
                      role="tab"
                      aria-controls="tab-3"
                      aria-selected={activeButton === 3}
                      onClick={() => handleTabClick(3)}
                    >
                      h.ORDER
                    </button>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
          <div className="col-span-5">
            <div ref={targetRefs[0]}>
              <div className="container relative md:mt-24 mt-24">
                <div className="flex md:grid-cols-2 grid-cols-1 items-center mt-20 gap-[30px]">
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Software</h6>
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                      <LanguageTranslation id="products.tab4.product1.title" />
                    </h3>
                    <p className="font-semibold text-slate-500 max-w-3xl text-xl">
                      <LanguageTranslation id="products.tab4.product1.desc" />
                    </p>
                    <div className="lg:me-8 mt-12">
                      <img
                        className="p-6 rounded-lg  dark:shadow-gray-800"
                        src={imgPosHWave}
                        alt=""
                      />
                    </div>
                  </motion.div>
                </div>
              </div>

              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div
                      className="relative wow animate__animated animate__fadeInLeft"
                      data-wow-delay=".3s"
                    >
                      <img src={imgPosHWave4} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div
                      className="lg:ms-8 wow animate__animated animate__fadeInRight"
                      data-wow-delay=".3s"
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product1.advantage1.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product1.advantage1.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage1.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage1.feature2" />
                        </li>
                      </ul>
                    </div>
                  </motion.div>
                </div>
              </div>

              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHWave1} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product1.advantage2.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product1.advantage2.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage2.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage2.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage2.feature3" />
                        </li>

                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage2.feature4" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHWave3} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product1.advantage3.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product1.advantage3.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage3.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage3.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product1.advantage3.feature3" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div ref={targetRefs[1]}>
              <div className="container relative md:mt-36 mt-24">
                <div className="flex lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-20 gap-[30px]">
                  <div className="lg:col-span-6">
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Software</h6>
                      <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.title" />
                      </h3>
                      <p className="font-semibold text-slate-500 max-w-3xl text-xl">
                        <LanguageTranslation id="products.tab4.product2.desc" isArray />
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>

              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-24 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHPos1} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.advantage1.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product2.advantage1.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage1.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage1.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />{' '}
                          <LanguageTranslation id="products.tab4.product2.advantage1.feature3" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage1.feature4" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage1.feature5" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHPos2} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.advantage2.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product2.advantage2.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage2.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage2.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage2.feature3" />
                        </li>

                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage2.feature4" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHPos3} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.advantage3.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product2.advantage3.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage3.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage3.feature2" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHPos4} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.advantage4.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product2.advantage4.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage4.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage4.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage4.feature3" />
                        </li>

                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage4.feature4" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHPos5} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.advantage5.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product2.advantage5.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage5.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage5.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage5.feature3" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img src={imgPosHPos6} className="rounded-lg  dark:shadow-gray-800" alt="" />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product2.advantage6.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product2.advantage6.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage6.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage6.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product2.advantage6.feature3" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div ref={targetRefs[2]}>
              <div className="container relative md:mt-36 mt-24">
                <div className="flex md:grid-cols-2 grid-cols-1 items-center mt-36 gap-[30px]">
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Software</h6>
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                      <LanguageTranslation id="products.tab4.product3.title" />
                    </h3>
                    <p className="font-semibold text-slate-500 max-w-3xl text-xl">
                      <LanguageTranslation id="products.tab4.product3.desc" isArray />
                    </p>
                    <div className="lg:me-8 mt-12">
                      <img
                        className="p-6 rounded-lg  dark:shadow-gray-800"
                        src={imgPosHStore}
                        alt=""
                      />
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img
                        src={imgPosHStore1}
                        className="rounded-lg  dark:shadow-gray-800 max-w-md"
                        alt=""
                      />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product3.advantage1.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product3.advantage1.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage1.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage1.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage1.feature3" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img
                        src={imgPosHStore2}
                        className="rounded-lg  dark:shadow-gray-800"
                        alt=""
                      />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product3.advantage2.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product3.advantage2.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage2.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage2.feature2" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img
                        src={imgPosHStore3}
                        className="rounded-lg  dark:shadow-gray-800 max-w-md"
                        alt=""
                      />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product3.advantage3.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product3.advantage3.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage3.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage3.feature2" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative order-1 md:order-2 wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img
                        src={imgPosHStore4}
                        className="rounded-lg  dark:shadow-gray-800 max-w-md"
                        alt=""
                      />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product3.advantage4.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product3.advantage4.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage4.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product3.advantage4.feature2" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div ref={targetRefs[3]}>
              <div className="container relative md:mt-36 mt-24">
                <div className="flex md:grid-cols-2 grid-cols-1 items-center mt-36 gap-[30px]">
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Software</h6>
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                      <LanguageTranslation id="products.tab4.product4.title" />
                    </h3>
                    <p className="font-semibold text-slate-500 max-w-3xl text-xl">
                      <LanguageTranslation id="products.tab4.product4.desc" isArray />
                    </p>
                    <div className="lg:me-8 mt-12">
                      <img
                        className="p-6 rounded-lg  dark:shadow-gray-800"
                        src={imgPosHOrder}
                        alt=""
                      />
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className="container relative md:mt-24 mt-24 md:mb-36">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center md:mt-36 mt-24 gap-[30px]">
                  <div
                    className="relative wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <img
                        src={imgPosHOrder1}
                        className="rounded-lg  dark:shadow-gray-800 max-w-md"
                        alt=""
                      />
                      <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl" />
                    </motion.div>
                  </div>

                  <div
                    className="lg:me-8 wow animate__animated animate__fadeInRight"
                    data-wow-delay=".3s"
                  >
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                      viewport={{ once: true, amount: 'some' }}
                    >
                      <h6 className="text-indigo-600 text-sm font-bold mb-2">Advantage</h6>
                      <h4 className="mb-4 text-2xl leading-normal font-semibold">
                        <LanguageTranslation id="products.tab4.product4.advantage1.title" />
                      </h4>
                      <p className="font-semibold text-slate-500">
                        <LanguageTranslation id="products.tab4.product4.advantage1.desc" isArray />
                      </p>
                      <ul className="list-none font-semibold text-slate-500 mt-4">
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product4.advantage1.feature1" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product4.advantage1.feature2" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product4.advantage1.feature3" />
                        </li>
                        <li className="mb-1 flex items-center">
                          <BsCheckCircle className="text-indigo-600 text-base me-2 min-w-[18px]" />
                          <LanguageTranslation id="products.tab4.product4.advantage1.feature4" />
                        </li>
                      </ul>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
