import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';

import { FaFacebookF, FaInstagram, FaTwitter, MdKeyboardArrowRight } from 'assets/icons/icons';
import logoLight from 'assets/images/logo-light.png';
import LanguageTranslation from '../LanguageTranslation';

export default function Footer() {
  const footerLinks = [
    {
      route: '/products/0',
      title: <LanguageTranslation id="footer.link.product.diamond" />,
      liClass: 'mt-[10px]',
    },
    {
      route: '/products/1',
      title: <LanguageTranslation id="footer.link.product.bss" />,
      liClass: 'mt-[10px]',
    },
    {
      route: '/products/2',
      title: <LanguageTranslation id="footer.link.product.itOutsourcing" />,
      liClass: 'mt-[10px]',
    },
    {
      route: '/products/3',
      title: <LanguageTranslation id="footer.link.product.hPos" />,
      liClass: 'mt-[10px]',
    },
  ];
  const footerCompany = [
    {
      route: '/aboutus',
      title: <LanguageTranslation id="footer.link.company.aboutUs" />,
      liClass: '',
    },
    {
      route: '/history',
      title: <LanguageTranslation id="footer.link.company.history" />,
      liClass: 'mt-[10px]',
    },
    {
      route: '/ipRights',
      title: <LanguageTranslation id="footer.link.company.ipRights" />,
      liClass: 'mt-[10px]',
    },
    {
      route: '/recruit',
      title: <LanguageTranslation id="footer.link.company.recruit" />,
      liClass: 'mt-[10px]',
    },
    {
      route: '/contact',
      title: <LanguageTranslation id="footer.link.company.contactUs" />,
      liClass: 'mt-[10px]',
    },
  ];
  return (
    <div>
      <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[25px]">
                  <div className="lg:col-span-4 md:col-span-12">
                    <p className="font-semibold text-[22px] focus:outline-none">
                      <img src={logoLight} className="l-light" width="138" height="24" alt="" />
                    </p>
                    {/* <Link to="/#" className="text-[22px] focus:outline-none">
                                            <img src={logo_light} alt="" />
                                        </Link> */}
                    <p className="mt-6 font-semibold text-gray-300">
                      <LanguageTranslation id="footer.address" />
                      <br />
                      <LanguageTranslation id="footer.tel" /> : 070-3554-7318
                    </p>

                    <ul className="list-none mt-5 space-x-1 space-y-1">
                      <li className="inline">
                        <Link
                          to="/"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaFacebookF className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="/"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaInstagram className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="/"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaTwitter className="text-sm" />
                        </Link>
                      </li>
                    </ul>

                    <div className="md:text-start text-center mt-5">
                      <p className="font-semibold mb-0">
                        Copyright @Hingom Corporation. All rights reserved.
                      </p>
                    </div>
                    <div className="grid mt-5 items-center">
                      <Link to="/privacy.html" target="_blank">
                        <p className="mb-0 text-sm">
                          <LanguageTranslation id="footer.privacyPolicy" />
                        </p>
                      </Link>
                    </div>
                  </div>

                  <div className="lg:col-span-2 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      <LanguageTranslation id="footer.link.company.title" />
                    </h5>
                    <ul className="list-none footer-list mt-6">
                      {footerCompany.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="font-semibold text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"
                          >
                            <MdKeyboardArrowRight className="text-xl me-1" /> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      <LanguageTranslation id="footer.link.product.title" />
                    </h5>
                    <ul className="list-none footer-list mt-6">
                      {footerLinks.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="font-semibold text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"
                          >
                            <MdKeyboardArrowRight className="text-xl me-1" /> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">Newsletter</h5>
                    <p className="font-semibold mt-6">
                      <LanguageTranslation id="footer.newsletter" />
                    </p>
                    <div className="grid grid-cols-1">
                      <div className="foot-subscribe my-3">
                        <div className="form-label font-semibold">
                          Your email <span className="text-red-600">*</span>
                        </div>
                        <div className="form-icon relative mt-2">
                          <Icon.Mail className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            id="email"
                            type="email"
                            className="form-input ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0"
                            placeholder="Email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>

                      <button
                        type="button"
                        id="submitsubscribe"
                        name="send"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
