import logoDark from 'assets/images/logo-dark.png';
import logoLight from 'assets/images/logo-light.png';
import 'assets/libs/@mdi/font/css/materialdesignicons.min.css';
import 'assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LanguageSwitch from '../LanguageSwitch';
import LanguageTranslation from '../LanguageTranslation';

export default function Navbar(props) {
  const { navClass, navJustify } = props;

  window.addEventListener('scroll', windowScroll);

  const [isMenu, setisMenu] = useState(false);

  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById('navigation')) {
      const anchorArray = Array.from(
        document.getElementById('navigation').getElementsByTagName('a')
      );
      anchorArray.forEach((element) => {
        element.addEventListener('click', (elem) => {
          const target = elem.target.getAttribute('href');
          if (target !== '') {
            if (elem.target.nextElementSibling) {
              const submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle('open');
            }
          }
        });
      });
    }
  };

  useEffect(() => {
    activateMenu();
    // window.scrollTo(0, 0);
  }, []);

  function windowScroll() {
    const navbar = document.getElementById('topnav');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      if (navbar !== null) {
        navbar?.classList.add('nav-sticky');
      }
    } else if (navbar !== null) {
      navbar?.classList.remove('nav-sticky');
    }

    const mybutton = document.getElementById('back-to-top');
    if (mybutton != null) {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        mybutton.classList.add('flex');
        mybutton.classList.remove('hidden');
      } else {
        mybutton.classList.add('hidden');
        mybutton.classList.remove('flex');
      }
    }
  }

  const activateMenu = () => {
    const menuItems = document.getElementsByClassName('sub-menu-item');
    if (menuItems) {
      let matchingMenuItem = null;
      for (let idx = 0; idx < menuItems.length; idx += 1) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }
      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        const immediateParent = getClosest(matchingMenuItem, 'li');

        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        let parent = getClosest(immediateParent, '.child-menu-item');
        if (parent) {
          parent.classList.add('active');
        }

        parent = getClosest(parent || immediateParent, '.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          const parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }

          const parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          const parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function matchesSelector(s) {
          // eslint-disable-next-line react/no-this-in-sfc
          const matches = (this.document || this.ownerDocument).querySelectorAll(s);
          let i = matches.length;
          // eslint-disable-next-line no-plusplus
          while (--i >= 0 && matches.item(i) !== this) {
            /* empty */
          }
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  let navClassNames = '';

  switch (navClass) {
    case 'nav-light':
      navClassNames = '';
      break;
    case 'nav-sticky':
      navClassNames = 'bg-white dark:bg-slate-900';
      break;
    default:
      break;
  }

  const navbarHtml = (
    <nav id="topnav" className={`defaultscroll ${navClassNames}`}>
      <div className="container relative">
        {navClass === 'nav-light' ? (
          <Link className="logo" to="/index">
            <span className="inline-block dark:hidden">
              <img src={logoDark} className="l-dark" width="138" height="24" alt="" />
              <img src={logoLight} className="l-light" width="138" height="24" alt="" />
            </span>
            <img
              src={logoLight}
              width="138"
              height="24"
              className="hidden dark:inline-block"
              alt=""
            />
          </Link>
        ) : (
          <Link className="logo" to="/index">
            <img
              src={logoDark}
              width="138"
              height="24"
              className="inline-block dark:hidden"
              alt=""
            />
            <img
              src={logoLight}
              width="138"
              height="24"
              className="hidden dark:inline-block"
              alt=""
            />
          </Link>
        )}
        <LanguageSwitch className={`defaultscroll ${navClassNames}`} />
        <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
          <ul className={`navigation-menu ${navClass} ${navJustify}`}>
            <li>
              <Link to="/index" className="sub-menu-item">
                <p className="text-[17px] font-semibold">
                  <LanguageTranslation id="navBar.home" />
                </p>
              </Link>
            </li>

            <li className="has-submenu parent-menu-item">
              <Link to="/aboutus">
                <p className="text-[17px] font-semibold">
                  <LanguageTranslation id="navBar.aboutUs" />
                </p>
              </Link>
              <span className="submenu-arrow" />
              <ul className="submenu">
                <li>
                  <Link to="/aboutus" className="sub-menu-item">
                    <p className="text-md font-semibold">
                      <LanguageTranslation id="navBar.aboutUs" />
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/history" className="sub-menu-item">
                    <p className="text-md font-semibold">
                      <LanguageTranslation id="navBar.history" />
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/ipRights" className="sub-menu-item">
                    <p className="text-md font-semibold">
                      <LanguageTranslation id="navBar.ipRights" />
                    </p>
                  </Link>
                </li>
                {/* <li><Link to="/" className="sub-menu-item">인재채용</Link></li> */}
                {/* <li><Link to="/contact" className="sub-menu-item">오시는 길</Link></li> */}
              </ul>
            </li>

            <li>
              <Link to="/products" className="sub-menu-item">
                <p className="text-[17px] font-semibold">
                  <LanguageTranslation id="navBar.products" />
                </p>
              </Link>
            </li>

            <li>
              <Link to="/recruit" className="sub-menu-item">
                <p className="text-[17px] font-semibold">
                  <LanguageTranslation id="navBar.recruit" />
                </p>
              </Link>
            </li>

            <li>
              <Link to="/contact" className="sub-menu-item">
                <p className="text-[17px] font-semibold">
                  <LanguageTranslation id="navBar.contact" />
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
  return <div>{navbarHtml}</div>;
}
