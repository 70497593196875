import 'react-18-image-lightbox/style.css';
import { motion } from 'framer-motion';

import {
  RiLayoutLine,
  RiComputerLine,
  RiShieldFlashLine,
  RiBracesLine,
  RiCloudWindyLine,
  RiToolsLine,
} from 'assets/icons/icons';
import { Link } from 'react-router-dom';
import LanguageTranslation from '../../../component/LanguageTranslation';

export default function ITOutsourcingTab(props) {
  return (
    <div className="w-full">
      <div className="relative md:mt-24 mt-16">
        <section className="py-20 w-full table relative background-effect bg-[url('assets/images/products/it_main_bg.png')] bg-center bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-slate-900/70" />
          <div className="relative">
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
              viewport={{ once: true, amount: 'some' }}
            >
              <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl text-white font-semibold">
                  <LanguageTranslation id="products.tab3.title" />
                </h3>

                <p className="font-semibold text-white/80 max-w-3xl mx-auto">
                  <LanguageTranslation id="products.tab3.subTitle" isArray />
                </p>
              </div>
            </motion.div>
          </div>
        </section>
        <section className="relative md:py-24 py-16">
          <div className="container relative">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
              <div className="lg:col-span-8 md:col-span-12">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8 } }}
                  viewport={{ once: true, amount: 'some' }}
                >
                  <div className="grid grid-cols-1 pb-8">
                    <h6 className="text-indigo-600 text-sm font-bold mb-2">Our Service</h6>
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                      <LanguageTranslation id="products.tab3.info1.title" isArray />
                    </h3>

                    {/* <p className="text-slate-500 max-w-xl">
                      흰곰은 자체 노하우를 바탕으로 전반적인 고객사의 정보시스템를 제공합니다.
                    </p> */}
                  </div>
                </motion.div>
                <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="px-6 py-10 min-h-[377px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                      <RiComputerLine className="h-12 w-12 text-indigo-600" />

                      <div className="content mt-7">
                        <Link
                          to="/"
                          className="title h5 text-lg font-semibold hover:text-indigo-600"
                        >
                          <LanguageTranslation id="products.tab3.info1.data1.title" />
                        </Link>
                        <p className="font-semibold text-slate-500 mt-3">
                          <LanguageTranslation id="products.tab3.info1.data1.desc" />
                        </p>
                      </div>
                    </div>
                  </motion.div>

                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="px-6 py-10 min-h-[377px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                      <RiShieldFlashLine className="h-12 w-12 text-indigo-600" />

                      <div className="content mt-7">
                        <Link
                          to="/"
                          className="title h5 text-lg font-semibold hover:text-indigo-600"
                        >
                          <LanguageTranslation id="products.tab3.info1.data2.title" />
                        </Link>
                        <p className="font-semibold text-slate-500 mt-3">
                          <LanguageTranslation id="products.tab3.info1.data2.desc" />
                        </p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="px-6 py-10 min-h-[377px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                      <RiCloudWindyLine className="h-12 w-12 text-indigo-600" />

                      <div className="content mt-7">
                        <Link
                          to="/"
                          className="title h5 text-lg font-semibold hover:text-indigo-600"
                        >
                          <LanguageTranslation id="products.tab3.info1.data3.title" />
                        </Link>
                        <p className="font-semibold text-slate-500 mt-3">
                          <LanguageTranslation id="products.tab3.info1.data3.desc" />
                        </p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="px-6 py-10 min-h-[377px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                      <RiBracesLine className="h-12 w-12 text-indigo-600" />

                      <div className="content mt-7">
                        <Link
                          to="/"
                          className="title h5 text-lg font-semibold hover:text-indigo-600"
                        >
                          <LanguageTranslation id="products.tab3.info1.data4.title" />
                        </Link>
                        <p className="font-semibold text-slate-500 mt-3">
                          <LanguageTranslation id="products.tab3.info1.data4.desc" />
                        </p>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>

              <div className="lg:col-span-4 md:col-span-12">
                <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-[30px]">
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="px-6 py-10 min-h-[377px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                      <RiToolsLine className="h-12 w-12 text-indigo-600" />

                      <div className="content mt-7">
                        <Link
                          to="/"
                          className="title h5 text-lg font-semibold hover:text-indigo-600"
                        >
                          <LanguageTranslation id="products.tab3.info1.data5.title" />
                        </Link>
                        <p className="font-semibold text-slate-500 mt-3">
                          <LanguageTranslation id="products.tab3.info1.data5.desc" />
                        </p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1, transition: { delay: 0.1, duration: 0.8 } }}
                    viewport={{ once: true, amount: 'some' }}
                  >
                    <div className="px-6 py-10 min-h-[377px] shadow-md hover:shadow-lg dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                      <RiLayoutLine className="h-12 w-12 text-indigo-600" />

                      <div className="content mt-7">
                        <Link
                          to="/"
                          className="title h5 text-lg font-semibold hover:text-indigo-600"
                        >
                          <LanguageTranslation id="products.tab3.info1.data6.title" />
                        </Link>
                        <p className="font-semibold text-slate-500 mt-3">
                          <LanguageTranslation id="products.tab3.info1.data6.desc" />
                        </p>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>

              <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]" />
              <div className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
